.wrap_container{
  font-family: 'Open Sans', sans-serif;
}
.highlight-color{
  color: #563e8f;
}
::placeholder {
  color: #a7a7a8;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #a7a7a8;
}

::-ms-input-placeholder {
  color: #a7a7a8;
}
body, html{
  margin:0;
  padding:0;
}
.intro-section{
  background: url("../images1/bg.png") no-repeat center top;
  background-size: cover;
  position: relative;
  @media screen and (min-width: 1024px) {
    &:after{
      position: absolute;
      content: '';
      width: 100%;
      background: #fff;
      height: 123px;
      transform: rotate(-4deg);
      bottom: -88px;
      transform-origin: left;
      left: 0;
      right: 0;

    }
  }

}
.container-title{
  font-size: 48px;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
  &:after{
    content: '';
    height: 4px;
    display: block;
    background: #563e8f;
    width: 70%;
    left: 15%;
    position: relative;
    margin-top: 11px;
  }
  &.access-service-title{
    &:after{
      width: 35%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.semi-bold{
  font-weight: 600;
}
.paragrap_con p{
  font-family: 'Open Sans', sans-serif;
  color: #292929;
  font-size: 18px;

  @media screen and (min-width: 769px) {
    font-size: 22px;
  }
}
.custom-size{
  @media screen and (min-width: 768px) {
    max-width: 20% !important;
    flex: 0 0 20%!important;
    position: relative;
    &:after{
      content: '';
      height: 114px;
      width: 2px;
      background: url("../images1/divider.jpg") no-repeat center;
      position: absolute;
      right: 0;
      top: -4px;

    }
    &:last-child{
      &:after{
        content: none;
      }
    }
  }
}
.block-5{
  text-align: center;
  @media screen and (min-width: 768px) {
    max-width: 20% !important;
    flex: 0 0 20%!important;
  }
}
.number_block_text{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  .number-title{
    font-size: 30px;
    color: #563e8f;
    font-weight: 700;
  }
}
.registration-block{
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  p{
    margin-bottom: 1rem;
  }
  padding: 28px;
  border-radius: 8px;
  box-shadow: 0 0 26px -4px #ccc;
  h2{
    font-size: 28px;
    font-weight: 700;
  }
  input{
    border: 0;
    width: 100%;
    border-bottom: 1px solid #929090;
    padding:4px;
    font-size: 14px;
    color: #707070;
  }
  input[type=file]::-webkit-file-upload-button{
    visibility: hidden;
  }
  select{
    border:0;
    border-bottom: 1px solid #929090;
    //padding:4px;
    border-radius: 0;
    font-size: 14px;
    background-color: transparent;
    display: block;
    color: #444;
    line-height: 1.3;
    padding: .3em 1.4em .5em .3em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../images1/select-arrow.png");
    background-repeat: no-repeat;
    background-position: center right;
  }
  textarea{
    width: 100%;
    font-size: 14px;
    border: 0;
  }
  button{
    font-size: 20px;
    color: #fff;
    background: #993a88;
    background: -moz-linear-gradient(left,  #993a88 0%, #3e428f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #993a88 0%,#3e428f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #993a88 0%,#3e428f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#993a88', endColorstr='#3e428f',GradientType=1 ); /* IE6-9 */

    border: 0;
    padding: 6px 37px;
    border-radius: 25px;
  }
}
.border-container, .gradient-bg{
  border-radius: 8px;
  padding: 2px;
  background: #3e418f; /* Old browsers */
  background: -moz-linear-gradient(top,  #3e418f 0%, #983a88 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #3e418f 0%,#983a88 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #3e418f 0%,#983a88 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e418f', endColorstr='#983a88',GradientType=0 ); /* IE6-9 */

}
.white-bg{
  background: #fff;
  border-radius: 8px;
  padding: 22px 7px;
  min-height: 168px;
  p{
    margin-top: 10px;
    margin-bottom: 0;
  }
}
.para-style{
  &:after{
    content: '';
    height: 4px;
    display: block;
    background: #563e8f;
    width: 46%;
    left: 0;
    position: relative;
    margin-top: 24px;
  }
}
.certified-section{

  background: url("../images1/certified-bg.jpg") no-repeat center top;
  background-size: cover;
  h3{
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
  p{
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
    margin-top: 16px;
    span{
      display: block;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}
.great-place-section{
  background: url("../images1/bg.png") no-repeat center top;
  background-size: cover;
  h2{
  font-size: 36px;
    color: #424291;
  }
  h3{
    font-size: 28px;
    color: #292929;
  }
  @media screen and (min-width: 768px) {
    h2{
    font-size: 50px;
    }
    h3{
      font-size: 35px;
    }
  }
}
.pos-rel{
  position: relative;
  z-index: 1;
}
